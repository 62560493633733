import { createMuiTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// import Staatliches from "../assets/fonts/Staatliches-Regular.ttf";
// import Raleway from "../assets/fonts/Raleway-Regular.ttf";
import "@fontsource/staatliches";
import "@fontsource/raleway";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
      white: "#FFFFFF",
    },
    secondary: {
      main: "#0000FF",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "Raleway, Arial",
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontFamily: "Raleway",
      textAlign: "justify",
    },
    button: {
      fontFamily: "Staatliches",
      textTransform: "uppercase",
      fontSize: 20,
      letterSpacing: 0.4,
    },
    h1: {
      fontFamily: "Staatliches",
      fontSize: 120,
    },
    h2: {
      fontFamily: "Staatliches",
      fontSize: 120,
      writingMode: "tb-rl",
      transform: "rotate(-180deg)",
      padding: 50,
      lineHeight: 1,
    },
    h3: {
      fontFamily: "Staatliches",
      fontSize: 30,
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Staatliches",
      fontSize: 24,
      textTransform: "uppercase",
    },
    h5: {
      fontFamily: "Staatliches",
      fontSize: 18,
      textTransform: "uppercase",
    },
    menuItem: {
      fontFamily: "Staatliches",
      fontSize: 30,
      textTransform: "uppercase",
    },
    b: {
      fontColor: "secondary",
    },
    questionAnswer__answer: {
      fontFamily: "Raleway",
      textAlign: "right",
      fontSize: 20,
      lineHeight: 24,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
});

export default theme;
