import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Seo from "./seo";
import Footer from "./footer";
import Header from "./header";
import theme from "../theme/theme";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Grid, Container, CssBaseline } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    paddingTop: 65,
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container>
          <Header />

          <Container maxWidth="sm" component="main" className={classes.root}>
            {children}
          </Container>

          <Footer />
        </Grid>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
