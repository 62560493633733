import React from "react";
import { Grid, Container } from "@material-ui/core";
const Footer = () => {
  return (
    <footer>
      <Grid>
        <Container></Container>
        <Container></Container>
      </Grid>
    </footer>
  );
};

export default Footer;
