import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import {
  Grid,
  Container,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Menu as MenuIcon, AccountCircle } from "@material-ui/icons/";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    padding: 0,
  },

  title: {
    flexGrow: 1,
  },
  toolbar: {
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
  },
  menuPaper: {
    width: "100%",
    maxWidth: 1280,
    left: 0,
    right: 0,
    top: 0,
    borderRadius: 0,
    height: "100%",
    maxHeight: "100%",
    backgroundColor: theme.palette.action.secondary,
    marginLeft: "auto",
    marginRight: "auto",
    "& .MuiMenu-list": {
      maxWidth: 1280,
    },

    backgroundColor: theme.palette.secondary.main,
  },
  menuItem: {
    fontFamily: "Staatliches",
    fontSize: 18,
    textTransform: "uppercase",
    color: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    "& a": {
      color: "#FFFFFF",
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    strapiGlobal,
    allStrapiSexualOrientation,
    allStrapiGender,
  } = useStaticQuery(graphql`
    {
      strapiGlobal {
        siteName
      }

      allStrapiSexualOrientation {
        edges {
          node {
            slug
            name
          }
        }
      }
      allStrapiGender {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `);

  return (
    <div>
      <IconButton
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
        marginThreshold={0}
        PaperProps={{}}
        // anchorOrigin={{ vertical: "bottom" }}
        // transformOrigin={{ vertical: "top" }}
        keepMounted
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.menuPaper }}
      >
        <Grid container>
          <Grid item xs={12}>
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to={"/"}>Home</Link>
            </MenuItem>
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to={"/lgbtq"}>LGBTQIA*</Link>
            </MenuItem>
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to={"/queer"}>Queer!</Link>
            </MenuItem>
          </Grid>

          <Grid item xs={12}>
            {allStrapiGender.edges.map((genderTopic, i) => (
              <MenuItem
                onClick={handleClose}
                key={`gender__${genderTopic.node.slug}`}
                className={classes.menuItem}
              >
                <Link to={`/gender/#${genderTopic.node.slug}`}>
                  {genderTopic.node.name}
                </Link>
              </MenuItem>
            ))}
          </Grid>
          <Grid item xs={12}>
            {allStrapiSexualOrientation.edges.map(
              (sexualOrientationTopic, i) => (
                <MenuItem
                  onClick={handleClose}
                  key={`sexual-orientation__${sexualOrientationTopic.node.slug}`}
                  className={classes.menuItem}
                >
                  <Link
                    to={`/sexuelle-orientierung/#${sexualOrientationTopic.node.slug}`}
                  >
                    {sexualOrientationTopic.node.name}
                  </Link>
                </MenuItem>
              )
            )}
          </Grid>
          <Grid item xs={12}>
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to={"/feedback-support"}>Feedback und Support</Link>
            </MenuItem>
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to={"/impressum"}>Impressum</Link>
            </MenuItem>
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to={"/datenschutz"}>Datenschutz</Link>
            </MenuItem>
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
};

export default NavBar;
