import React from "react";
import {
  Grid,
  Container,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Slide,
  useScrollTrigger,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Seo from "./seo";
import NavBar from "./nav";
import { graphql, useStaticQuery } from "gatsby";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbarWrapper: {
    maxWidth: 1280,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  appBarWrapper: {
    // position: "fixed",
    // width: 1280,
    // left: "50%",
    // right: "50%",
    // transform: "translate(-50%)",
  },
  appBar: {},
  toolbar: {
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Header = () => {
  const classes = useStyles();
  const {
    strapiHomepage: { seo },
  } = useStaticQuery(graphql`
    {
      strapiHomepage {
        seo {
          metaTitle
          metaDescription
          shareImage {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <Seo seo={seo} />
      <HideOnScroll>
        <AppBar
          className={classes.appBar}
          position="sticky"
          color={"white"}
          elevation={0}
        >
          <div className={classes.toolbarWrapper}>
            <Toolbar
              disableGutters={true}
              className={(classes.root, classes.toolbar)}
            >
              <NavBar />
            </Toolbar>
          </div>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
};

export default Header;
